import React, { useEffect, useState } from "react";
import { Styles } from "./styles/searchTeacherCompo";
import Select from "react-select";
import { Icon } from "@iconify/react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { student, subjectGrade } from "../puglins/Utils/models";
import {
  Checkbox,
  CheckboxGroup,
  CustomProvider,
  RangeSlider,
  Slider,
} from "rsuite";
import { useTranslation } from "react-i18next";
import notistack from "../puglins/notistack";
import { useDarkMode } from "@rbnd/react-dark-mode";
import { colors } from "./common/element/elements";
import Loader from "./common/Loader";
import moment from "moment";
import noNetworkAction from "../puglins/Utils/errorManage";
import { useSelector } from "react-redux";
import { t } from "i18next";
// import { Button, Modal } from "react-bootstrap";
// import LineChart from "../pages/Teacher/myAdvert/LineChart";

function SearchTeacherCompo() {
  const [data, setData] = useState();
  const [filterData, setFilterData] = useState();
  const [rateValue, setRateValue] = useState([0, 0]);
  const [hoursValue, setHoursValue] = useState([0, 0]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    data ? setTimeout(() => setLoader(false), 1000) : setLoader(true);
  }, [data]);
  const [level, setLevel] = useState([
    "Basic",
    "Beginner Level",
    "Intermediate Level",
    "Advanced Level",
    "Professional Level",
  ]);
  const [selectedLevel, setSelectedLevel] = useState(level);
  const history = useHistory();
  const params = useLocation();
  const { t, i18n } = useTranslation();
  const { mode } = useDarkMode();
  const [limit, setLimit] = useState(12);
  const [sortData, setSortData] = useState(0);
  const [associateSubjects, setAssociateSubjects] = useState([]);
  const [selectedAssociate, setSelectedAssociate] = useState([]); // Initially, no checkbox is selected
  const customStyles = (isDarkMode) => ({
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode === "dark" ? "#333" : "#edecec66",
      color: isDarkMode === "dark" ? "white" : "black",
      // border: state.isFocused ? `1px solid ${isDarkMode==='dark' ? 'white' : 'blue'}` : '1px solid #ccc',
      border: "none",
      boxShadow: "none",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode === "dark" ? "#333" : "white",
      color: isDarkMode === "dark" ? "white" : "black",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? isDarkMode === "dark"
          ? "#444"
          : "#eee"
        : isDarkMode === "dark"
          ? "#333"
          : "white",
      color: state.isSelected
        ? isDarkMode === "dark"
          ? "white"
          : "black"
        : isDarkMode === "dark"
          ? "white"
          : "black",
      "&:hover": {
        backgroundColor: isDarkMode === "dark" ? "#555" : colors?.orange,
        color: isDarkMode === "dark" ? "white" : "black",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected
        ? isDarkMode === "dark"
          ? "white"
          : "black"
        : "inherit",
    }),
  });
  const { authUserData } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );
  const options = [
    { label: t("sortData1"), value: 1 },
    { label: t("sortData2"), value: 2 },
    { label: t("sortData3"), value: 3 },
    { label: t("sortData4"), value: 4 },
  ];


  useEffect(() => {
    if (params?.state?.level_id === "course") getAdvertsBySubject();
    else getSearchTecherData(params?.state);
  }, [params?.state]);
  useEffect(() => {
    setSortData(2);
    filterApply();
  }, [data]);

  /* The above code is a React useEffect hook that updates the state of `associateSubjects` based on the
`i18n.language` value. It uses the `setAssociateSubjects` function to update the state by filtering
and mapping the `defaultValue` array in the previous state. */
  useEffect(() => {
    setAssociateSubjects((prev) => ({
      ...prev,
      selectedAssociate: prev?.defaultValue
        ?.filter((item) => {
          if (
            prev?.selectedAssociate?.includes(
              i18n.language === "en"
                ? item?.subject_name
                : item?.subject_name_en
            )
          )
            return item;
        })
        ?.map((item) =>
          i18n.language === "fr" ? item?.subject_name : item?.subject_name_en
        ),
    }));
  }, [i18n.language]);

  const handleClearFilter = () => {
    setAssociateSubjects((prev) => ({
      ...prev,
      selectedAssociate: prev?.defaultValue?.map((i) =>
        i18n.language === "fr" ? i?.subject_name : i?.subject_name_en
      ),
    }));
  };

  /**
   * The `takeAClass` function pushes a new entry to the browser history with specific data related to a
   * class item.
   * @param item - The `item` parameter seems to be an object containing the following properties:
   */
  const takeAClass = (item) => {
    history.push({
      pathname: "/Take-A-Class",
      state: {
        id: item?.advert?._id,
        feedback: item?.feedback,
        teacherId: item?.teacherDetails?._id,
      },
    });
  };

  /**
   * The function `getSearchTecherData` fetches teacher data based on search criteria and sets various
   * state values accordingly.
   * @param searchData - The `searchData` parameter is used to pass the search criteria for finding
   * teacher data. It is likely an object containing information such as subject, grade,
   * based on which the teacher data will be fetched.
   */
  const getSearchTecherData = (searchData) => {
    subjectGrade
      .getSearchTeacher(searchData)
      .then((response) => {
        setFilterData(response?.data);
        delete filterData?.adverts;
        setRateValue([0, response?.data?.max_rate]);
        setHoursValue([0, response?.data?.max_hours]);
        setData(response?.data?.adverts);
        setAssociateSubjects({
          defaultValue: response?.data?.associated_subject,
          selectedAssociate: response?.data?.associated_subject.map((i) =>
            i18n.language === "fr" ? i?.subject_name : i?.subject_name_en
          ),
        });
        setLimit(12);
      })
      .catch((error) => {
        error?.message === "Network Error" && noNetworkAction(history);
        console.log("Error while get teacher data", error);
      });
  };

  /**
   * The `filterApply` function filters data based on hourly rate, total time duration, selected level,
   * and associated subjects, and can also sort the filtered data based on different criteria.
   * @returns The `filterApply` function is returning the filtered and sorted data based on the filter
   * criteria and sorting options provided in the code snippet. The filtered data is stored in the
   * `temp` variable and then returned at the end of the function.
   */
  const filterApply = () => {
    // To apply searching Filter
    let filterData = data;

    let temp = filterData
      ?.filter((item) => {
        // filter according to hourly basis
        return (
          item?.advert?.hourly_rate >= rateValue[0] &&
          item?.advert?.hourly_rate <= rateValue[1]
        );
      })
      .filter((item) => {
        // filter according to Total duration  basis
        return (
          item?.advert?.total_time_duration >= hoursValue[0] &&
          item?.advert?.total_time_duration <= hoursValue[1]
        );
      })
      .filter((item) => {
        // filter according to selected level
        for (let i = 0; i < selectedLevel?.length; i++) {
          if (
            "advert_level" in item?.advert
              ? item?.advert?.advert_level == selectedLevel[i]
              : "Basic" == selectedLevel[i]
          ) {
            return item;
          } else {
            continue;
          }
        }
      })
      .filter((item) => {
        // Filter according to selected associated subjects
        if (selectedAssociate?.length > 0) {
          for (let i = 0; i < selectedAssociate.length; i++) {
            if (
              "associated_subject" in item?.advert &&
              item?.advert?.associated_subject
                ?.map((i) =>
                  i18n.language === "fr" ? i?.subject_name : i?.subject_name_en
                )
                .includes(selectedAssociate[i])
            ) {
              return item;
            } else {
              continue;
            }
          }
        } else {
          // If no subjects are selected, show all items
          return item;
        }

        return null; // No match found, filter out this item
      });

    if (sortData) {
      if (sortData === 1) {
        temp?.sort(
          (a, b) => b?.feedback?.average_rating - a?.feedback?.average_rating
        );
      } else if (sortData === 2) {
        const sortOrder = [1, 2, 0];

        temp?.sort(
          (a, b) =>
            sortOrder.indexOf(a?.teacherDetails?.online) -
            sortOrder.indexOf(b?.teacherDetails?.online)
        );
      } else if (sortData === 3) {
        temp?.sort((a, b) => a?.advert?.hourly_rate - b?.advert?.hourly_rate);
      } else if (sortData === 4) {
        temp?.sort(
          (a, b) =>
            b?.advert?.total_time_duration - a?.advert?.total_time_duration
        );
      }
    }

    return temp;
  };

  /**
   * The function `addToFavCollection` adds an item to a favorite collection based on a given status and
   * handles success and error notifications.
   * @param item - The `item` parameter in the `addToFavCollection` function is expected to be an object
   * containing information about an advertisement. It likely has a property named `advert` which in turn
   * has an `_id` property representing the advertisement ID.
   * @param status - The `status` parameter in the `addToFavCollection` function is used to determine the
   * type of action to be taken. If `status` is true, it will trigger a success notification with the
   * message "advertnoti1", and if `status` is false, it will trigger
   */
  const addToFavCollection = (item, status) => {
    let sendData = {
      advertisement_id: item?.advert?._id,
      type: status,
    };
    student
      .addToFavCollection(sendData)
      .then(() => {
        status
          ? notistack.success(t("advertnoti1"))
          : notistack.success(t("advertnoti2"));
        getSearchTecherData(params?.state);
      })
      .catch((err) => {
        err?.message === "Network Error" && noNetworkAction(history);
        notistack.error(t("advertnotiError"));
      });
  };

  /**
   * The function `getAdvertsBySubject` retrieves and processes advertisement data based on a specified
   * subject for a student.
   */
  const getAdvertsBySubject = () => {
    student
      .getAdvertBySubject(params?.state?.subject_id)
      .then((res) => {
        console.log(res);
        setFilterData(res);
        delete filterData?.adverts;
        setRateValue([0, res?.max_rate]);
        setHoursValue([0, res?.max_hours]);
        setData(res?.adverts);
      })
      .catch((error) => {
        error?.message === "Network Error" && noNetworkAction(history);
        console.log("Error while getting data by student Id");
      });
  };

  /**
   * The `formatTime` function takes a number of minutes as input and returns a formatted time string in
   * the format "HH:MM".
   * @param minutes - The `minutes` parameter in the `formatTime` function represents the total number
   * of minutes that you want to convert into a formatted time string.
   * @returns The `formatTime` function returns a formatted time string in the format "hours:minutes",
   * where the hours and minutes are derived from the input `minutes` parameter.
   */
  const formatTime = (minutes) => {
    const duration = moment.duration(minutes, "minutes");
    const hours = Math.floor(duration.asHours());
    const mins = duration.minutes();

    const formattedTime = `${hours}:${mins.toString().padStart(2, "0")}`;

    return formattedTime;
  };

  return (
    <>
      <Styles>
        <CustomProvider theme={mode}>
          {loader ? (
            <Loader />
          ) : (
            <div className="main container p-2 pb-4">
              <div className="row px-2  ">
                <div className="col-12   d-flex  align-items-center py-2">
                  <div className="col-md-9 col-6 ">
                    <h6>
                      {t("teachers")}{" "}
                      {
                        data && data[0]?.advert?.subject_name &&
                        (i18n.language === 'fr' ? data[0]?.advert?.subject_name_fr : data[0]?.advert?.subject_name)
                      }
                    </h6>
                    <p>
                      {data?.length} {t("avbTeacher")}
                    </p>
                  </div>
                  <div className="col-md-3 col-6  d-flex  flex-column align-items-end  ">
                    <div className="w-100">
                      <span className="sort-by fw-bold fs-5">
                        {t("sortBy")}:
                      </span>
                      <Select
                        options={options}
                        value={options.find((i) => i?.value === sortData)}
                        className="selectbox w-100"
                        styles={customStyles(mode)}
                        placeholder="Sort By"
                        onChange={(e) => setSortData(e?.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="menu-filter col-12  py-2 d-flex   ">
                  <ul className="nav menu-nav">
                    <li className="nav-item dropdown active">
                      <button
                        className="btn rounded p-2 px-4  me-2 "
                        data-toggle="dropdown"
                      >
                        {t("rate")}
                      </button>
                      <ul className="dropdown list-unstyled mt-2">
                        <li className="nav-item">
                          <div className="nav-link  ">
                            <p style={{ fontSize: "10px" }}>
                              {t("avgRating")}: 200 €
                            </p>
                            <p style={{ fontSize: "12px" }}>
                              {t("yourMaxBudget")}: 500{" "}
                            </p>
                            <div className="d-flex justify-content-between ">
                              <div>
                                <p>Min</p>
                                {rateValue[0]}
                              </div>
                              <div>
                                <p>Max</p>
                                {rateValue[1]}
                              </div>
                            </div>
                            <div>
                              <RangeSlider
                                progress
                                style={{ marginTop: 16, paddingRight: "10px" }}
                                value={rateValue}
                                onChange={(value) => {
                                  setRateValue(value);
                                }}
                                max={filterData?.max_rate}
                              />
                            </div>
                            <div className="mt-3 text-center ">
                              <button
                                className="button-bg-none mx-1 p-2 px-2"
                                onClick={() => {
                                  setRateValue([0, filterData?.max_rate]);
                                }}
                              >
                                {t("clearFilter")}
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="nav menu-nav">
                    <li className="nav-item dropdown active">
                      <button
                        className="btn rounded p-2 px-4  me-2 "
                        data-toggle="dropdown"
                        style={{
                          width: '90px',
                          height: '33px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          display: 'inline-block',
                        }}
                      >

                        {t("tutoringHrs")}
                      </button>
                      <ul className="dropdown list-unstyled mt-2">
                        <li className="nav-item">
                          <div className="nav-link  ">
                            <p style={{ fontSize: "12px" }}>
                              {t("tutoringHoursBetween")}{" "}
                            </p>
                            <div className="d-flex justify-content-between ">
                              <div>
                                <p>Min</p>
                                {formatTime(hoursValue[0])}
                              </div>
                              <div>
                                <p>Max</p>
                                {formatTime(hoursValue[1])}
                              </div>
                            </div>
                            <div>
                              <RangeSlider
                                progress
                                style={{ marginTop: 16, paddingRight: "10px" }}
                                value={hoursValue}
                                onChange={(value) => {
                                  setHoursValue(value);
                                }}
                                max={filterData?.max_hours}
                                tooltip={false}
                              />
                            </div>
                            <div className="mt-3 text-center ">
                              <button
                                className="button-bg-none mx-1 p-2 px-2"
                                onClick={() =>
                                  setHoursValue([0, filterData?.max_hours])
                                }
                              >
                                {t("clearFilter")}
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="nav menu-nav">
                    <li className="nav-item dropdown active">
                      <button
                        className="btn rounded p-2 px-4  me-2 "
                        data-toggle="dropdown"
                      >
                        {t("grade")}
                      </button>
                      <ul className="dropdown list-unstyled mt-2">
                        <li className="nav-item">
                          <div className="nav-link  ">
                            <p style={{ fontSize: "12px" }}>
                              {t("TutorGrade")}
                            </p>
                            <div>
                              <CheckboxGroup
                                name="checkboxList"
                                value={selectedLevel}
                                onChange={(value) => setSelectedLevel(value)}
                              >
                                {level.map((item) => (
                                  <Checkbox key={item} value={item}>
                                    {item}
                                  </Checkbox>
                                ))}
                              </CheckboxGroup>
                            </div>
                            <div className="mt-3 text-center ">
                              <button
                                className="button-bg-none mx-1 p-2 px-2"
                                onClick={() => setSelectedLevel(level)}
                              >
                                {t("clearFilter")}
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  {(associateSubjects?.defaultValue?.length > 0 || associateSubjects?.selectedAssociate?.length > 0) && (
                    <ul className="nav menu-nav">
                      <li className="nav-item dropdown active">
                        <button className="btn rounded p-2 px-4 me-2" data-toggle="dropdown">
                          {t("section")}
                        </button>
                        <ul className="dropdown list-unstyled mt-2">
                          <li className="nav-item">
                            <div className="nav-link">
                              <p style={{ fontSize: "12px" }}>
                                {t("sectionPara")}
                              </p>
                              <div>
                                {associateSubjects?.defaultValue?.length > 0 ? (
                                  <CheckboxGroup
                                    name="checkboxList"
                                    value={selectedAssociate}
                                    onChange={(value) => {
                                      setSelectedAssociate(value); 
                                      filterApply(); 
                                    }}
                                  >
                                    {associateSubjects?.defaultValue
                                      ?.map((i) =>
                                        i18n.language === "fr" ? i?.subject_name : i?.subject_name_en
                                      )
                                      ?.map?.((item) => (
                                        <Checkbox key={item} value={item}>
                                          {item}
                                        </Checkbox>
                                      ))}
                                  </CheckboxGroup>
                                ) : (
                                  <p>{t("noSubcategoriesAvailable")}</p>
                                )}
                              </div>
                              <div className="mt-3 text-center">
                                <button
                                  className="button-bg-none mx-1 p-2 px-2"
                                  onClick={() => {
                                    setSelectedAssociate([]);
                                    filterApply(); // Reapply filter after clearing checkboxes
                                  }}
                                >
                                  {t("clearFilter")}
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  )}

                </div>

                {data?.length > 0 ? (
                  <>
                    <div className="col-12 gap-5 py-2 d-flex justify-content-evenly flex-wrap  ">
                      {filterApply()
                        .slice(0, limit)
                        ?.map((item) => {
                          return (
                            <div
                              className=" card-main col-12 p-2 w-auto rounded   "
                              key={item?.advert?._id}
                            >
                              <div className="d-flex ">
                                <div className="p-2 d-flex flex-column align-items-center justify-content-center">
                                  <div
                                    className="image-div "
                                    onClick={() =>
                                      history.push({
                                        pathname: "/Teacher-Details",
                                        state: {
                                          advertId: item?.advert?._id,
                                          teacherId: item?.teacherDetails?._id,
                                        },
                                      })
                                    }
                                  >
                                    <img
                                      src={item?.teacherDetails?.image}
                                      alt="image"
                                    />
                                  </div>
                                  <div className="py-2 d-flex justify-content-center text-white fw-bold   ">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        id="toggleSwitch"
                                        checked={item?.teacherDetails?.online}
                                        // onChange={handleSwitchChange}
                                        disabled
                                      />
                                      <span
                                        className="slider py-1"
                                        style={{
                                          ...(!item?.teacherDetails?.online
                                            ? {
                                              paddingLeft: 25,
                                              backgroundColor: "red",
                                            }
                                            : item?.teacherDetails?.online === 2
                                              ? {
                                                paddingLeft: 5,
                                                backgroundColor: "goldenrod",
                                              }
                                              : {
                                                paddingLeft: 5,
                                                backgroundColor: "green",
                                              }),
                                          ...(i18n.language === "fr" && {
                                            fontSize: "10px",
                                          }),
                                        }}
                                      >
                                        {item?.teacherDetails?.online === 1
                                          ? t("online")
                                          : item?.teacherDetails?.online === 2
                                            ? t("busy")
                                            : t("offline")}
                                      </span>
                                    </label>
                                  </div>
                                  {item?.teacherDetails?.online === 2 &&
                                    item?.time_difference && (
                                      <DisplayTime
                                        time={item?.time_difference}
                                      />
                                    )}
                                </div>
                                <div className="card-part-two p-2 d-flex ">
                                  <div className="d-flex flex-column px-1  ">
                                    <div
                                      className="icon-heart"
                                      onClick={() =>
                                        addToFavCollection(
                                          item,
                                          item?.advert?.like_status ? 1 : 0
                                        )
                                      }
                                    >
                                      <Icon
                                        icon="mdi:heart"
                                        width={25}
                                        color={
                                          item?.advert?.like_status ? "red" : ""
                                        }
                                        className="float-end"
                                      />
                                    </div>
                                    <h6>{item?.teacherDetails?.first_name}</h6>

                                    {/* ------Review section---- */}
                                    <div className="details-section">
                                      <div className="py-2 ">
                                        <Icon
                                          icon="material-symbols:star"
                                          width={20}
                                          color="goldenrod"
                                        />
                                        <span className="ms-2 ">
                                          <span className="fw-bold ">
                                            {item?.feedback?.average_rating?.toFixed(
                                              2
                                            )}
                                          </span>{" "}
                                          ({item?.feedback?.total_reviews}{" "}
                                          {t("reviews")})
                                        </span>
                                      </div>
                                      {/* -----Hours section  */}
                                      <div className="hours-div py-2 ">
                                        <Icon icon="mdi:clock" width={20} />
                                        <span className="ms-2 ">
                                          {formatTime(
                                            item?.advert?.total_time_duration
                                          )}{" "}
                                          {t("hrsOfTutoring2")}
                                        </span>
                                      </div>
                                      {/* -----Talent level section------ */}
                                      <div className="d-flex justify-content-between align-item-center">
                                        <div className="py-2 text-success  ">
                                          <Icon
                                            icon="game-icons:upgrade"
                                            width={20}
                                            color="darkgreen"
                                          />
                                          <span className="ms-2 ">
                                            {"advert_level" in item?.advert
                                              ? item?.advert?.advert_level
                                              : "Basic"}
                                          </span>
                                        </div>
                                        {/* <div
                                      className="py-2   "
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleShow()}
                                    >
                                      <Icon
                                        icon="cil:graph"
                                        width={20}
                                        color={colors?.orange}
                                      />
                                      <span className="ms-2 ">{t("view")}</span>
                                    </div> */}
                                      </div>
                                    </div>
                                    {/* ------Price section----- */}
                                    <div className="price-div">
                                      <h6 className="my-3 fw-bolder text-center  ">
                                        {item?.advert?.hourly_rate}€ / hr
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* ------Button section---- */}
                              <div className="take-btn   ">
                                {authUserData?.online === 2 ? (
                                  <p
                                    className="text-center fw-bold"
                                    style={{ color: colors?.orange }}
                                  >
                                    {t("warningClassesComplete")}
                                  </p>
                                ) : item?.teacherDetails?.online === 2 &&
                                  item?.waiting_room_status &&
                                  !item?.waiting_room &&
                                  item?.membership !== "Basic" ? (
                                  <button
                                    className="btn border-success border-2 fw-bold w-100 "
                                    onClick={() => takeAClass(item)}
                                  >
                                    {t("enterWaitingRoom")}
                                  </button>
                                ) : item?.teacherDetails?.online === 1 &&
                                  !item?.waiting_room ? (
                                  <button
                                    className="btn border-success border-2 fw-bold w-100 "
                                    onClick={() => takeAClass(item)}
                                  >
                                    {t("takeAClass")}
                                  </button>
                                ) : item?.waiting_room ? (
                                  <p
                                    className="fw-bold text-center"
                                    style={{ color: colors?.darkblue }}
                                  >
                                    {t("alreadyWaiting")}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {/* {item?.teacherDetails?.online && (
                              <button
                                className="btn border-success border-2 fw-bold w-100 "
                                onClick={() => takeAClass(item)}
                              >
                                {t("takeAClass")}
                              </button>
                            )} */}
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    {limit < filterApply().length && (
                      <div className="text-center my-4">
                        <button
                          className="btn  m-auto mt-2 px-5 "
                          onClick={() => setLimit((prev) => prev + 12)}
                        >
                          {t("loadMore")}{" "}
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center py-5 my-5 ">
                    <h5>{t("noTeachersAvaliable")}</h5>
                  </div>
                )}
              </div>
            </div>
          )}
        </CustomProvider>
      </Styles>
    </>
  );
}

export function DisplayTime({ time }) {
  const [timeDisplay, setTimeDisplay] = useState();
  useEffect(() => {
    manageRemainingTime(time);
  }, []);
  const manageRemainingTime = (time) => {
    let seconds = time * 60;

    setInterval(() => {
      const duration = moment.duration(seconds, "seconds");
      seconds--;
      setTimeDisplay(duration);
    }, 1000);
  };
  return (
    <>
      {timeDisplay && timeDisplay > 0 ? (
        <h6 className="fs-6 rounded p-1 text-center" style={{ width: "145px" }}>
          {t("timeLeft")}:{" "}
          {timeDisplay?.hours() +
            ":" +
            timeDisplay?.minutes() +
            ":" +
            timeDisplay?.seconds()}
        </h6>
      ) : (
        ""
      )}
    </>
  );
}

export default SearchTeacherCompo;
