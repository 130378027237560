import axios from "../axios";
import auth from "./auth";

const Teacher = {
  getUniversity() {
    return new Promise((resolve, reject) => {
      axios
        .get("/get-university")
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getDegree() {
    return new Promise((resolve, reject) => {
      axios
        .get("/get-degree")
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getAreaOfStudy() {
    return new Promise((resolve, reject) => {
      axios
        .get("/get-area-of-study")
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  setPaymentSetting(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("/payment-setting", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  createAdvert(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("adverts/create-advert", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  updateGeneralInformation(data) {
    console.log('data',data);
    
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put("teachers/update-teacher", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getAllAdvertByTeacher() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("get-teacher-adverts")
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getStudentById(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`teachers/${id}`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  updateAdvertStatus(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("adverts/change-status", id)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  updateUploadFile(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
      .post("users/upload-file", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  deleteAdvertByID(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .delete("adverts/delete-advert", id)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  updateAdvertDetails(id, data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`adverts/update-advert/${id}`, data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  classRequestStatus(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`/invitations/${data?.id}/${data?.status}`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  upadateNotificationSetting(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("set-teacher-notification", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  giveReviews(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("review-class", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  uploadIdProof(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("teachers/upload-id-proof", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getPaymnetDetails() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("payment-details")
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getPaymentDetailsById(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`payment-record/${id}`)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getSubscription() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`subscription/get-subscriptions`)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  buySubscriptionStripe(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`subscription/purchase-subscription/stripe`, id)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  buySubscriptionPaypal(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`subscription/purchase-subscription/paypal`, id)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  deleteAccount(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .delete("teachers/delete-teacher", id)
        .then(({ data }) => {
          resolve(data);
          auth.destroyToken("distory");
        })
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getCardDetails() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`/get-card-details`)
        .then(({ data }) => resolve(data))
        .catch((error) => {
          reject(error);
          error?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  giveReport(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("/send-report-email", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  updateStatus(id,status) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`/update-online-status/${id}/${status}`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getOverAllStacs() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`/get-teacher-statistics`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  updateWaitingRoomStatus() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`/subscription/update-waiting-room-status`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getUserSubscriptions() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`/subscription/get-user-subscription`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  updateAutoRenewalStatus(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`/subscription/update-auto-renewal/${id}`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getStudentCount(){
    return new Promise((resolve,reject) => {
      axios.setToken();
      axios
        .get(`get-student-online-count`)
        .then(({data}) => resolve(data))
        .catch((err)=>{
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    })
  } 
}

export default Teacher;
