import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { colors } from "./common/element/elements";
import { Icon } from "@iconify/react";
import { auth, payment, teacher } from "../puglins/Utils/models";
import notistack from "../puglins/notistack";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { getClassHistory } from "../actions/student";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";
import { onGoingClassDetails } from "../actions/teacher";
import { AcceptTimer } from "./Timer";
import { getAuthUser } from "../actions/initialData";
import manageClassStorage from "../helper/OnGoingClassManage";
import { DisplayTime } from "./SearchTeacher";

const objModal = {
  classId: null,
  model: false,
};

function Alert({ paymentModel, setPaymentModel, stripePayment, handleUnload }) {
  const [show, setShow] = useState(paymentModel?.model);
  const [ratingData, setRatingData] = useState({ rating: "", feedback: "" });
  const { authUserData } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );
  const handleClose = () => {
    setPaymentModel(objModal);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [timerStatus, setTimerStatus] = useState(true);


  useEffect(() => {
    handleShow();
  }, []);

  /* The above code is a React useEffect hook that sets a timeout to trigger a function if the
  paymentModel's modelNo is equal to 8. If the condition is met, it will call the handleUnload
  function with the argument "idle" and then call the handleClose function after a delay of 10
  seconds (10000 milliseconds). The useEffect hook also includes a cleanup function that clears the
  timeout if the component unmounts or if the paymentModel's modelNo changes. */

  useEffect(() => {
    console.log("paymentModel inside Alert component:", paymentModel);
    if (paymentModel?.modelNo === 8) {
      console.log("Setting modal to show");

      const timeoutRef = setInterval(() => {
        console.log("Timeout triggered after 5 seconds");

        handleUnload("idle");
        handleClose();
      }, 30000);

      // Cleanup on unmount or when paymentModel changes
      return () => {
        if (timeoutRef)
          clearTimeout(timeoutRef);
      };
    }
  }, [paymentModel?.modelNo, t]);


  // manage Ratingg
  const ratingChanged = (e, name) => {
    setRatingData((prev) => ({
      ...prev,
      [name]: e?.target ? e?.target?.value : e,
    }));
  };

  // Hit Api to submit reviews and also to check the field validation
  const onReviewSubmit = () => {
    if (!ratingData?.rating || !ratingData?.feedback)
      notistack.warning(t("reviewWarning"));
    else {
      teacher
        .giveReviews({
          ...ratingData,
          class_id: paymentModel?.classId?.class_id,
        })
        .then(() => {
          notistack.success(t("reviewMsg"));
          handleClose();
          dispatch(getClassHistory(history, authUserData?._id));
        })
        .catch(() => notistack.error(t("reviewMsg2")));
    }
  };

  // Accept and reject classes
  const actionOnClassRequest = (data, status) => {
    let sendData = {
      id: data,
      status: status,
    };
    teacher
      .classRequestStatus(sendData)
      .then((res) => {
        if (res?.data?.status === 2) {
          setPaymentModel((prev) => ({
            ...prev,
            classId: res?.data,
            model: true,
            modelNo: 2,
          }));
        }
        dispatch(getClassHistory(history, authUserData?._id));
        (status === 3 || res?.data?.status === 7) && handleClose();
      })
      .catch((errr) => console.log("error", errr));
  };

  // To handle pop-up don't show again
  const managePopupDontShow = () => {
    auth
      .popupStatus()
      .then(() => {
        // setShow(null);
        paymentModel?.classId();
        setPaymentModel({
          classId: null,
          model: false,
        })
        dispatch(getAuthUser(history));
        notistack.success(t("updated"));
      })
      .catch((err) => console.log("Error while updating don't show"));
  };

  return (
    <>
      {/* ------ To show pop to student about join metting link on request accepted */}
      {paymentModel?.modelNo == 1 && (
        <Modal show={show}>
          <div className="row  py-3 px-3">
            <div className="col-8 h4">{t("congratulation")}!</div>
          </div>
          <hr className="my-0" />
          <Modal.Body>
            <div className="text-center   ">
              <p
                className={`my-2 ${paymentModel?.classId?.adverts?.teacher_online_status === 2 &&
                  "fw-bold fs-5"
                  }`}
              >
                {paymentModel?.classId?.adverts?.teacher} {t("modalOnePara")}
              </p>
              {paymentModel?.classId?.adverts?.teacher_online_status !== 2 && (
                <>
                  <p className="mt-3 ">
                    {t("modalOnePara2")}{" "}
                    {paymentModel?.classId?.adverts?.teacher}
                  </p>
                  <div
                    className="mt-3  w-auto p-2 rounded text-white  "
                    onClick={() => {
                      manageClassStorage.saveDetails({
                        token: paymentModel?.classId?.adverts?.link,
                        sessionName: paymentModel?.classId?.adverts?.subject,
                        id: paymentModel?.classId?.adverts?.class_id,
                        time: paymentModel?.classId?.time_difference,
                      })

                      history.push({
                        pathname: "/ZoomClasses",
                      });
                      handleClose();
                    }}
                    style={{
                      backgroundColor: colors?.orange,
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {/* <a
                  className="mt-3  "
                  href={paymentModel?.classId?.adverts?.link}
                  target="_blank"
                  onClick={() => handleClose()}
                  style={{ backgroundColor: colors?.orange }}
                > */}{" "}
                    {t("joinClass")}
                    {/* </a> */}
                  </div>
                  <p className="mt-3 fw-bold  ">
                    {t("modalOnePara3")}
                    {paymentModel?.classId?.duration} {t("mins")}
                  </p>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              {t("close")}
            </Button>
            {/*<Button className="border-0 " style={{ backgroundColor: "#3ac16a" }}>
          Submit
        </Button> */}
            {/* Please do payment in {formatTime()} .Or the request will cancelled. */}
          </Modal.Footer>
        </Modal>
      )}

      {/* ------ To show pop to teacher about host meeting room on request accepted */}
      {paymentModel?.modelNo == 2 && authUserData?.online === 1 && (
        <Modal show={show}>
          <div className="row d-flex justify-content-between py-3 px-3">
            <div className="col-8 h4">{t("success")}</div>
          </div>
          <hr className="my-0" />
          <Modal.Body>
            <div className="text-center   ">
              <p className="my-2 ">{t("modalTwoPara")}</p>
              <p className="my-2 ">{t("modalTwoPara2")}</p>
              <h6 className="fw-bold my-4 ">
                {paymentModel?.classId?.amount.toFixed(2)} €
              </h6>
              <p className="mt-3 ">{t("modalTwoPara3")}</p>
              <div
                className="mt-3  w-auto  p-2 rounded text-white "
                onClick={() => {
                  manageClassStorage.saveDetails({
                    id: paymentModel?.classId?._id,
                    token: paymentModel?.classId?.start_meeting_url,
                    sessionName:
                      paymentModel?.classId?.advert_id?.subject_expertise
                        ?.subject_name,
                    time: paymentModel?.classId?.time_difference,
                  })

                  history.push({
                    pathname: "/ZoomClasses",
                  });
                }}
                style={{ backgroundColor: colors?.orange, cursor: "pointer" }}
              >
                {" "}
                {/* <a
                  className="mt-3 p-2 rounded text-white  "
                  href={paymentModel?.classId?.start_meeting_url}
                  target="_blank"
                  onClick={() => handleClose()}
                  style={{ backgroundColor: colors?.orange }}
                > */}{" "}
                {t("startClass")}
                {/* </a> */}
              </div>
              <p className="mt-3 fw-bold  ">
                {t("modalOnePara3")}
                {paymentModel?.classId?.duration} mins
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              {t("close")}
            </Button>
            {/* <Button className="border-0 " style={{ backgroundColor: "#3ac16a" }}>
          Submit
        </Button> */}
          </Modal.Footer>
        </Modal>
      )}

      {/* ------ To show pop to student on request rejected */}
      {paymentModel?.modelNo == 3 && (
        <Modal show={show} size="lg">
          <div className="row d-flex justify-content-between py-3 px-3">
            <div className="col-8 h4  ">{t("neverMind")}!</div>
          </div>
          <hr className="my-0" />
          <Modal.Body>
            <div className="text-center   ">
              <p className="my-2 ">{t("modalThreePara")}</p>
              <p className="my-2 ">{t("modalThreePara2")}</p>

              {paymentModel?.advertData?.length > 0 && (
                <p className="mt-4 ">{t("modalThreePara3")}</p>
              )}
              <div className="d-flex justify-content-center gap-5 mt-4">
                {paymentModel?.advertData?.length > 0 ? (
                  paymentModel?.advertData?.map((item) => {
                    return (
                      <div
                        className=" p-2 d-flex flex-column m-2 shadow-sm px-md-4 position-relative "
                        style={{
                          width: "fit-content",
                          border: "1px solid #dadada",
                          borderRadius: " 5px",
                        }}
                      >
                        <div className="w-100 d-flex align-items-center  ">
                          <div className="profileImage  ">
                            {item?.teacherDetails?.online ? (
                              <Icon
                                icon="pajamas:status-active"
                                className="position-absolute m-2 text-success "
                                width={17}
                              />
                            ) : (
                              <Icon
                                icon="pajamas:status-active"
                                className="position-absolute m-2 text-danger "
                                width={17}
                              />
                            )}
                            <img
                              src={item?.teacherDetails?.image}
                              alt="image"
                              height={100}
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                          <div className="ms-1 p-2 d-flex flex-column justify-content-between pb-3   ">
                            <div>
                              <p className="mb-2 fs-4 fw-bold text-start ">
                                {item?.teacherDetails?.first_name}
                              </p>
                              <div className="d-flex align-items-center  mb-2">
                                <Icon
                                  icon="material-symbols:star"
                                  width={20}
                                  color="goldenrod"
                                />
                                <span className="ms-2 ">
                                  <span className="fw-bold ">
                                    {item?.feedback?.average_rating.toFixed(2)}
                                  </span>{" "}
                                  ({item?.feedback?.total_reviews}{" "}
                                  {t("reviews")})
                                </span>
                              </div>
                              <div className="d-flex align-items-center mb-2">
                                <Icon
                                  icon="game-icons:upgrade"
                                  width={20}
                                  color="darkgreen"
                                />
                                <span className="ms-2 fs-6 ">
                                  {item?.advert?.advert_level}
                                </span>
                              </div>
                              <h6 className="mb-2 fw-bolder text-start  ">
                                {item?.advert?.hourly_rate}€ / hr
                              </h6>
                              <button
                                className="rounded p-2 px-4 fw-bold text-white "
                                style={{ backgroundColor: colors?.darkblue }}
                                onClick={() => {
                                  history.push({
                                    pathname: "/Take-A-Class",
                                    state: {
                                      id: item?.advert?._id,
                                      feedback: item?.feedback,
                                    },
                                  });
                                  handleClose();
                                }}
                              >
                                {t("takeAClass")}
                              </button>
                              <p
                                className="text-primary fw-bold text-center mt-2"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  history.push({
                                    pathname: "/Teacher-Details",
                                    state: {
                                      advertId: item?.advert?._id,
                                      teacherId: item?.teacherDetails?._id,
                                    },
                                  });
                                  handleClose();
                                }}
                              >
                                {t("viewProfile")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="fw-bold py-4 d-flex flex-column justify-content-center   ">
                    <Icon
                      icon="mingcute:sad-line"
                      width={80}
                      className="w-100 "
                      color={colors?.darkblue}
                    />
                    {t("modalThreePara4")}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              {t("close")}
            </Button>
            {/* <Button className="border-0 " style={{ backgroundColor: "#3ac16a" }}>
          Submit
        </Button> */}
          </Modal.Footer>
        </Modal>
      )}

      {/* ------ To show pop for feedback on meeting gets finished */}
      {paymentModel?.modelNo == 4 && (
        <Modal show={show}>
          <div className="row d-flex justify-content-between py-3 px-3">
            <div className="col-8 h4  "> {t("modalFourPara")}</div>
          </div>
          <hr className="my-0" />
          <Modal.Body>
            <div className="text-center">
              <p className="my-2 fw-bold  ">{t("modalFourPara2")}</p>
              <div className="d-flex justify-content-center ">
                <ReactStars
                  classNames="m-auto"
                  count={5}
                  onChange={(e) => ratingChanged(e, "rating")}
                  size={30}
                  activeColor="#ffd700"
                />
              </div>

              <div>
                <p className="fs-5 fw-bold text-start "> {t("feedback")}:</p>
                <textarea
                  maxLength={100}
                  style={{ maxHeight: "100px" }}
                  className="w-100 rounded border-light-subtle p-2 "
                  rows={5}
                  placeholder={t("placeHolderFeedback")}
                  onChange={(e) => ratingChanged(e, "feedback")}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              {t("close")}
            </Button>
            <Button
              className="border-0 "
              style={{ backgroundColor: colors?.darkblue }}
              onClick={() => onReviewSubmit()}
            >
              {t("submit")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* ------ To show pop for Meeting request accept when teacher is in class */}
      {paymentModel?.modelNo == 5 && (
        <Modal show={show}>
          <div className="row d-flex justify-content-between py-3 px-3">
            <div className="col-12 h4  d-flex justify-content-between align-items-center">
              {" "}
              {t("newClassRequest")}
              <Icon
                icon="icon-park-twotone:close-one"
                color="red"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setPaymentModel((prev) => ({
                    ...prev,
                    classId: null,
                    model: false,
                    modelNo: 0,
                  }));
                }}
              />
            </div>
          </div>
          <hr className="my-0" />
          <Modal.Body>
            <div className="text-center">
              <p
                className="rounded p-1 fw-bold mb-3 text-white d-flex w-auto gap-2"
                style={{ background: colors?.darkblue }}
              >
                {t("accpetNote")}{" "}
                {<AcceptTimer onTimerComplete={setTimerStatus} />}{" "}
                {t("accpetNote2")}.
              </p>
              <div className="d-flex align-items-center justify-content-center">
                {/* -----img---- */}
                <img
                  src={paymentModel?.classId?.student_id?.image}
                  style={{
                    borderRadius: "25px",
                    height: "100px",
                    width: "100px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <p className="fw-bold text-center ">
                {paymentModel?.classId?.student_id?.first_name}
              </p>
              <p className=" text-center ">
                {t("subject")}:
                <span className="fw-bold ms-1">
                  {
                    paymentModel?.classId?.advert_id?.subject_expertise
                      ?.subject_name
                  }
                </span>
              </p>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <p>
                  {t("classDuration")}:
                  <span className="fw-bold ">
                    {" "}
                    {paymentModel?.classId?.duration >= 60
                      ? paymentModel?.classId?.duration / 60
                      : paymentModel?.classId?.duration}{" "}
                    {paymentModel?.classId?.duration === 60
                      ? "hr"
                      : paymentModel?.classId?.duration > 60
                        ? "hrs" : "min"}
                  </span>
                </p>
                <p>
                  {t("amountPaid")}:
                  <span className="fw-bold ">
                    {paymentModel?.classId?.amount.toFixed(2)} €
                  </span>
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-around">
            <Button
              variant="danger"
              className="col-5"
              onClick={() =>
                actionOnClassRequest(paymentModel?.classId?._id, 3)
              }
            >
              <Icon icon="ic:twotone-cancel" className="me-2" />
              {t("reject")}
            </Button>
            <Button
              className="border-0 col-5 "
              variant="success"
              onClick={() =>
                actionOnClassRequest(paymentModel?.classId?._id, 2)
              }
            >
              <Icon icon="flat-color-icons:ok" className="me-2" />
              {t("accept")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* ------ To show pop for Meeting is going to end soon and meeting is ended  */}
      {paymentModel?.modelNo == 6 && (
        <Modal show={show}>
          <Modal.Body>
            {paymentModel?.classId === 5 && (
              <>
                <p
                  className="fs-3  text-center"
                  style={{ color: colors?.orange }}
                >
                  {t("meetingIn5")}
                </p>
                <div className="d-flex justify-contents-center my-5">
                  <Icon
                    icon="twemoji:alarm-clock"
                    width={100}
                    className="m-auto"
                  />
                </div>
              </>
            )}
            {/* {paymentModel?.classId === 0 && (
              <>
                <p
                  className="fs-3  text-center"
                  style={{ color: colors?.orange }}
                >
                  {t("meetingEnd")}
                </p>
                <div className="d-flex justify-contents-center my-5">
                  <Icon
                    icon="flat-color-icons:end-call"
                    width={100}
                    className="m-auto"
                  />
                </div>
              </>
            )
            
              } */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() =>
                setPaymentModel({
                  classId: null,
                  model: false,
                })
              }
            >
              {t("close")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* ------ To show pop before proceed to class request  */}
      {paymentModel?.modelNo == 7 && (
        <Modal show={show}>
          <Modal.Header>
            <img
              src={process.env.PUBLIC_URL + "/assets/images/Logoheader.webp"}
              className="logo-image"
              alt="logo"
              loading="lazy"
              height={"50%"}
              width={"30%"}
            />
          </Modal.Header>
          <Modal.Body>
            <>
              <p className="text-center my-5">{t("takeClassBeforeMsg")}</p>
              <div className="d-flex align-items-center gap-3">
                <input
                  type="checkbox"
                  onChange={(e) => managePopupDontShow()}
                />{" "}
                {t("dontShow")}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                setPaymentModel({
                  classId: null,
                  model: false,
                })
                stripePayment();
              }}
            >
              {t("close")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* ------ To show pop-up when the user is Idle */}
      {paymentModel?.modelNo === 8 && (
        <Modal show={show}>
          <Modal.Header>
            <img
              src={process.env.PUBLIC_URL + "/assets/images/Logoheader.webp"}
              className="logo-image"
              alt="logo"
              loading="lazy"
              height={"50%"}
              width={"30%"}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="text-center my-5">
              {t("avalibleMsg1")}
            </p>
            <DisplayTime time={0.5} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => {
                setPaymentModel({
                  model: false,
                  modelNo:0
                })
              }}
            >
              {t("yes")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default Alert;
