import React, { Suspense, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthUser, setAccess } from "../actions/initialData";
import Loader from "../components/common/Loader";
import { teacher } from "../puglins/Utils/models";
import noNetworkAction from "../puglins/Utils/errorManage";
import notistack from "../puglins/notistack";
import StudentRoutes from "./studentRoutes";
import TeacherRoutes from "./teacherRoutes";
import HomeTwo from '../HomeTwo'

// Lazy load components
// const HomeTwo = React.lazy(() => import("../HomeTwo"));
const Login = React.lazy(() => import("../pages/account/Login"));
const ForgotPassword = React.lazy(() =>
  import("../pages/account/ForgotPassword")
);
const Index = React.lazy(() => import("../pages/account"));
const Register = React.lazy(() => import("../pages/account/Register"));
const Contact = React.lazy(() => import("../pages/contact/Contact"));
const PageNotFound = React.lazy(() => import("../pages/404/PageNotFound"));
const BlogGrid = React.lazy(() => import("../pages/blog/BlogGrid"));
const BlogDetails = React.lazy(() => import("../pages/blog/BlogDetails"));
const LegalNotice = React.lazy(() =>
  import("../pages/legalNotice/LegalNotice")
);
const TermsCondition = React.lazy(() =>
  import("../pages/termsCondition/TermsCondition")
);
const PrivacyPolicy = React.lazy(() =>
  import("../pages/privacyPolicy/PrivacyPolicy")
);
const HelpCenter = React.lazy(() => import("../pages/helpCenter/HelpCenter"));
const BecomeTeacher = React.lazy(() =>
  import("../pages/becomeTeacher/BecomeTeacher")
);
const AboutUs = React.lazy(() => import("../pages/whoAreWe/AboutUs"));
const OnlineCourses = React.lazy(() =>
  import("../pages/onlineCourses/OnlineCourses")
);
const WaitingRoom = React.lazy(() =>
  import("../pages/waitingRoom/WaitingRoom")
);
const NetworkError = React.lazy(() =>
  import("../components/common/NetworkError")
);
const MyLevel = React.lazy(() => import("../pages/myLevel/MyLevel"));
const PaymentSuccess = React.lazy(() =>
  import("../pages/Payments/PaymentSuccess")
);
const PaymentFail = React.lazy(() => import("../pages/Payments/PaymentFail"));
const Unsubscrbe = React.lazy(() => import("../components/Unsubscribe"));

function MainRoutes() {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const { authUserData } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );
  const { access,isLogin } = useSelector(({ intialDataReducer }) => intialDataReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const path = [
    "/dashboard",
    "/favourites",
    "/myclasses",
    "/myAccount",
    "/Subscription",
    "/updatemyadvert",
    "/myPublicProfile",
    "/myadvert",
    "/MyPayments",
    "/firstlogin",
    "/Take-A-Class",
    "/Teacher-Details",
    "/searchTeacher",
    "/ZoomClasses",
    "/ViewStatistics",
  ];

  useEffect(() => {
    let sessionData=sessionStorage.getItem("online");    
    if (localStorage.getItem("token")) {
      sessionData &&
        updateStatus(JSON.parse(sessionData));
      dispatch(getAuthUser(history));
    }
  }, [history]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    path.find(
      (item) => item === location?.pathname && dispatch(setAccess(true))
    );
    setLoading(false);
  }, [authUserData, location?.pathname]);

  // Update status of the Teacher
  const updateStatus = (status) => {
    teacher
      .updateStatus(status?.user_id, status?.status)
      .then(() => {})
      .catch((error) => {
        error?.message === "Network Error" && noNetworkAction(history);
        notistack.error("Error while updating Status");
      });
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={`/`} component={HomeTwo} />
        <Route path={`/login`} render={({ match }) =>
            isLogin ? <Redirect to="/dashboard" /> : <Login match={match} />
          } />
        <Route path={`/forgot-password/:id`} component={ForgotPassword} />
        <Route
          path={`/registrationMain/:Pusertype`}
          render={({ match }) =>
            token ? <Redirect to="/dashboard" /> : <Index match={match} />
          }
        />
        <Route
          path={`/registration/:Ptype/:PuserType`}
          render={({ match }) =>
            token ? <Redirect to="/dashboard" /> : <Register match={match} />
          }
        />
        <Route path={`/contact`} component={Contact} />
        <Route path={`/blog-grid`} component={BlogGrid} />
        <Route path={`/blog-details`} component={BlogDetails} />
        <Route path={`/legalNotice`} component={LegalNotice} />
        <Route path={`/termsCondition`} component={TermsCondition} />
        <Route path={`/privacyPolicy`} component={PrivacyPolicy} />
        <Route path={`/helpcenter`} component={HelpCenter} />
        <Route path={`/becomeTeacher`} component={BecomeTeacher} />
        <Route path={`/whoAreWe`} component={AboutUs} />
        <Route path={`/onlineCourses`} component={OnlineCourses} />
        <Route path={`/waitingRoom`} component={WaitingRoom} />
        <Route path={`/NetworkError`} component={NetworkError} />
        <Route path={`/MyLevel`} component={MyLevel} />
        <Route path={`/unSubscribe/:id/:lang`} component={Unsubscrbe} />
        {
          access &&
          (isLogin ? (
            <>
              {authUserData?.userType === 1 ? (
                <TeacherRoutes />
              ) : (
                <StudentRoutes />
              )}
            </>
          ) : (
            <Redirect to="/login" />
          ))}
        <Route path={`/paymentSuccess`} component={PaymentSuccess} />
        <Route path={`/paymentFail`} component={PaymentFail} />
        {/* <Route component={PageNotFound} /> */}
      </Switch>
    </Suspense>
  );
}

export default MainRoutes;
