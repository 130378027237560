import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .main {
    .sort-by{
      @media (max-width: 768px) {
        font-size: 12px !important;
       
      }
    }
    .card-main {
      position: relative;
      background-size: cover;
      overflow: hidden;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

      &:hover {
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
      }
      
      .icon-heart{
        svg{
          cursor: pointer;
        }
      }
    }

    .image-div {
      margin: 0px auto;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 80px;
      height: 24px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      border-radius: 34px;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      border-radius: 50%;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      transform: translateX(55px);
    }

    // .card-part-two:hover .feedback-section {
    //   display: block;
    // }
    // .card-part-two:hover .details-section {
    //   display: none;
    // }

    .details-section {
      width: 160px;
      height: 125px;
    }
  //   .review-para {
  //     overflow: hidden;
  //     -webkit-box-orient: vertical;
  //     -webkit-line-clamp: 3;
  //     display: -webkit-box;
  //     font-size: 12px;
  //     font-weight: 500;
  //     margin-left: 7%;
  //     width: 150px;
  //     margin-bottom: 0px;
  //   }
  //   .read-full {
  //     cursor: pointer;
  //     width: fit-content;
  //     font-size: 14px;
  //     color: blue;
  //     float: right;
  //   }
  //   .feedback-section {
  //     display: none;
  //     width: 160px;
  //     height: 100px;
  //   }
  // }

  ul.nav.menu-nav {
    li.nav-item {
      position: relative;

      a.nav-link {
        font-size: 14px;
        // color         : ${colors.black1};
        text-transform: uppercase;
        font-weight: 500;
        padding: 10px 10px 20px;

        i {
          font-size: 12px;
        }

        &:after {
          content: none;
        }

        &:hover {
          color: ${colors.darkblue};
        }

        @media (max-width: 1199px) {
          padding: 10px 2px 20px;
          letter-spacing: 0;
        }
      }

      ul.dropdown {
        position: absolute;
        left: 0;
        top: 80%;
        min-width: 190px;
        background: #fff;
        border: 1px solid ${colors.border1};
        text-align: left;
        padding: 0;
        border-radius: 5px;
        transition: 0.2s ease;
        opacity: 0;
        transform: scaleY(0);
        visibility: hidden;
        z-index: 999;
        transform-origin: center top 0;
        color: ${colors?.darkblue} !important;

        .nav-link{
          color: ${colors?.darkblue} !important;
        }
        li.nav-item {
          position: relative;

          a.nav-link {
            font-size: 13px;
            color: #000000 !important;
            padding: 10px 20px;
            text-transform: capitalize;
            font-weight: 400;
            margin-right: 0;
            border-bottom: 1px solid ${colors.border1};

            &:hover {
              color: ${colors.darkblue};

              i {
                color: #fff;
              }
            }

            i {
              float: right;
              margin-top: 4px;
            }
          }

          &:last-child {
            margin-left: 0;

            a.nav-link {
              border-bottom: none;
            }
          }

          ul.dropdown2 {
            position: absolute;
            top: 0;
            left: 100%;
            min-width: 180px;
            background: #fff;
            border: 1px solid red;
            transition: 0.2s ease;
            opacity: 0;
            transform: scaleY(0);
            visibility: hidden;
            z-index: 999;
            transform-origin: center top 0;
          }

          &:hover {
            ul.dropdown2 {
              opacity: 1;
              transform: scaleY(1);
              visibility: visible;
            }
          }
        }
      }

      &:hover {
        ul.dropdown {
          opacity: 1;
          transform: scaleY(1);
          visibility: visible;
        }
      }
    }
  }
`;
