import axios from "../axios";
import auth from "./auth";

const subjectGrade = {
  getGrade() {
    return new Promise((resolve, reject) => {
      axios
        .get("/grades")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getSubjects() {
    return new Promise((resolve, reject) => {
      axios
        .get("/subjects")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getLangauge() {
    return new Promise((resolve, reject) => {
      axios
        .get("/languages")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getSearchTeacher(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("/adverts/teacher-by-level-subject", data)
        .then((response) => resolve(response))
        .catch((error) => {
          reject(error);
          error?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getAdvertById(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("/adverts", id)
        .then((response) => resolve(response))
        .catch((error) => {
          reject(error);
          error?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getTeacherById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/teachers", id)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  // ----------------- Home page api's and unAuthorized api's
  getPopularAdverts() {
    return new Promise((resolve, reject) => {
      axios
        .get("/get-popular-subjects")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getBlogs() {
    return new Promise((resolve, reject) => {
      axios
        .get("/blog/get")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getBlogsById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`blog/get/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getAllAdverts() {
    return new Promise((resolve, reject) => {
      axios
        .get("/adverts")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getAllAdvertsById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`adverts/get-adverts-by-subject/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getTestimonial() {
    return new Promise((resolve, reject) => {
      axios
        .get("/get-five-top-teacher")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  sendContactEmail(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/contact-us", data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  manageNotification(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/read-notification/${id}`)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  manageNotificationViewed(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/viewed-notification/${id}`)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },

  fetchTeacherCount() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/get-teacher-count`)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};

export default subjectGrade;
