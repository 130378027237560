const manageClassStorage={
    saveDetails(data){
        localStorage.setItem('onClassDetails',JSON.stringify(data));
    },
    getDetails(){
        return JSON.parse(localStorage.getItem('onClassDetails')) || null;
    },
    deleteDetails(){
        localStorage.removeItem('onClassDetails')
    }
}

export default manageClassStorage;