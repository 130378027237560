import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .explorCourses {
    margin-top: 50px;
    .sec-title {
      h4 {
        color: ${colors?.darkblue};
      }
      .right-block {
        height: fit-content;
        border-radius: 20px;
        @media (max-width: 1000px) {
          font-size: 10px;
        }
        @media (max-width: 767px) {
          margin-top: 20px;
        }
        div {
          cursor: pointer;
          font-weight: 500;
          padding-left: 10px;
          padding-right: 5px;
        }
        .active {
          padding-left: 10px;
          padding-right: 5px;
          border-radius: 20px;
        }
      }
    }

    .card-blk {
      background: none;
      .img-blk {
        cursor: pointer;
      }
      @media (max-width: 767px) {
        width: 330px;
        object-fit: none;
        // background: ${colors?.orange};
        border: 1px solid ${colors?.orange};
      }

      img {
        object-fit: contain;
        @media (max-width: 767px) {
          width: 320px;
        }
      }

      .heading,
      .name {
        color: ${colors?.darkblue};
        font-size: 14px;
        font-weight: 500;
      }
      p {
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .icon-blk {
        .level-blk {
          text-transform: uppercase;

          .dot-blk {
            height: 10px;
            width: 10px;
          }
        }
      }
    }

    .card-container {
      box-shadow: inset 0px 0px 5px ${colors?.darkblue};
      border-radius: 1rem 1rem 0rem 0rem;
      overflow: scroll;
      overflow-y: hidden;

      ::-webkit-scrollbar {
        width: 0px;
        height: 4px;
      }
    }
  }
`;
